import PropTypes from "prop-types";
//import React, { useEffect } from "react";
//import $ from 'jquery';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import { setCategoryID, setCategoryCode } from "../../redux/actions/productActions";
import { setContent } from "../../redux/actions/contentAction";
import TopMenuAll from "../../util/TopMenuAll.js"; // **
import CategoryItemDepth3 from "./CategoryItemDepth3.jsx";
import CategoryItemDepth1 from "./CategoryItemDepth1.jsx";
import CategoryItemDepth2 from "./CategoryItemDepth2.jsx";

const NavMenuAll = ({
  props,
  strings,
  menuWhiteClass,
  sidebarMenu,
  categories,
  contents,
  setCategoryID,
  setCategoryCode,
  setContent,
}) => {
  const onClickCategory = (item) => {
    //lineage 정보로 최상위 카타고리 찾기
    const regex = /\/(\d+)\//;
    const match = item.lineage.match(regex);
    const extractedNumber = match ? match[1] : null;
    setCategoryCode((categories.find((item) => item.id === Number(extractedNumber)) || {}).code);
    //이동
    setCategoryID(item.id);
  };
  const [visibleIndices, setVisibleIndices] = useState(false);
  const memoizedCategories = useMemo(() => categories, [categories]);

  return (
    <div className="hideNav">
      <nav>
        <div className="depth d1">
          <div className="d1Unit">
            <ul className="lv1" data-depth="1">
              {memoizedCategories.map((item, index) => (
                <CategoryItemDepth1 item={item} onClickCategory={onClickCategory} key={index} />
              ))}
            </ul>
          </div>
        </div>
        <div className="depth d2 noChild">
          {memoizedCategories.map(
            (item, index) =>
              item.visible && (
                <CategoryItemDepth2
                  key={index}
                  item={item}
                  onClickCategory={onClickCategory}
                  isVisible={setVisibleIndices}
                  strings = {strings}
                />
              )
          )}
          <p className="parentReqMsg">
            <span>{strings["Select Top Menu"]}</span>
          </p>
        </div>
        {visibleIndices && (
          <div className="depth d3 noChild">
            {memoizedCategories.map(
              (item, index) =>
                item.visible &&
                item.children &&
                item.children.length > 0 && (
                  <Fragment key={index}>
                    {item.children.map(
                      (submenu, index2) =>
                        submenu.visible && (
                          <CategoryItemDepth3 key={index2} item={item} submenu={submenu} onClickCategory={onClickCategory} strings = {strings}/>
                        )
                    )}
                  </Fragment>
                )
            )}
            <p className="parentReqMsg">
              <span>{strings["Select Top Menu"]}</span>
            </p>
          </div>
        )}

        <div className="mobileTop">
          <img alt="" src="/assets/img/logo/logo.svg" />
          <button type="button" className="hideNavCloser">
            <span className="blind">{strings["Close"]}</span>
          </button>
        </div>
      </nav>
      <TopMenuAll />
    </div>
  );
};

NavMenuAll.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
  strings: PropTypes.object,
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCategoryID: (value) => {
      dispatch(setCategoryID(value));
    },
    setCategoryCode: (value) => {
      dispatch(setCategoryCode(value));
    },
    setContent: (value) => {
      dispatch(setContent(value));
    },
  };
};

export default connect(null, mapDispatchToProps)(multilanguage(NavMenuAll));
