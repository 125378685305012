import { store } from "../index";
import WebService from "./webService";
	
	// 숫자 콤마 변환 함수
	export const SetCharCommaFnc = (targetChar) => {
		if (targetChar === null || targetChar === undefined || targetChar === "") {
			return "";
		}
	
		const formattedNumber = targetChar.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	
		return formattedNumber;
	};
	
	// 정수 반환 함수
	// 정수 반환 함수
	export const setChar = (value) => {
		// 문자열인 경우 숫자로 변환
		if (typeof value === 'string') {
			// 문자열에서 통화 기호와 쉼표 제거
			value = value.replace(/[^\d.-]/g, '');
			value = parseFloat(value);
		}
	
		// 숫자인 경우 그대로 사용
		if (typeof value === 'number') {
			return value.toLocaleString('ko-KR', { style: 'currency', currency: 'KRW' });
		}
	
		// 숫자로 변환할 수 없는 경우 원래 값을 반환
		return value;
	};
	
	// 소수점 두자리 반환 함수
	export const SetformattedNumber = (targetChar) => {
		const formattedNumber = parseFloat(targetChar).toFixed(2);
		return formattedNumber;
	};
	
	// 숫자 콤마 제거 함수
	export const formatNumberWithCommas = (number) => {
		return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};
	
	export const calculateDiscountPercentage = (discountedPrice, originalPrice) => {
		return Math.round(((originalPrice - discountedPrice) / originalPrice) * 100);
	};
	
	export const transformVariants = (variants) => {
		return variants.map((variant) => ({
			id: variant.id,
			name: variant.name,
			value: variant.value,
		}));
	};
	
	export const formatFileSize = (size) => {
		// 파일 크기를 사람이 읽기 쉬운 형식으로 변환하는 함수
		if (size < 1024) return `${size} bytes`;
		else if (size >= 1024 && size < 1024 * 1024) return `${(size / 1024).toFixed(2)} KB`;
		else if (size >= 1024 * 1024 && size < 1024 * 1024 * 1024) return `${(size / (1024 * 1024)).toFixed(2)} MB`;
		else return `${(size / (1024 * 1024 * 1024)).toFixed(2)} GB`;
	};
	
	export const byteWordLenth = () => {
		return 50 * 1024 * 1024;
	};
	
	export const calculateBytes = (input) => {
		const encoder = new TextEncoder();
		const byteLength = encoder.encode(input).length;
		return byteLength;
	};
	
	export const muiLicense = async () => {
		const response = await WebService.EXT.get(`authorization/mui`);
		return response;
	};
	
