import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
const FooterCopyright = ({ footerLogo, spaceBottomClass, colorClass, merchant }) => {
	return (
		<div className="copyright">
			<div className="logo">
				<Link to={process.env.PUBLIC_URL + "/"}>
					{
						merchant.logo != null ? (
							<img alt="" src={merchant.logo.path} />
						) : (
							<img alt="" src="/assets/img/logo/logo.svg" />
						)
					}
				</Link>
			</div>
		</div>
	);
};
FooterCopyright.propTypes = {
	footerLogo: PropTypes.string,
	spaceBottomClass: PropTypes.string,
	colorClass: PropTypes.string
};
const mapStateToProps = state => {
	return {
		merchant: state.merchantData.merchant
	};
};
const mapDispatchToProps = dispatch => {
	return {
	};
};
export default connect(
	mapStateToProps,
	mapDispatchToProps
)(multilanguage(FooterCopyright));
// export default FooterCopyright;
