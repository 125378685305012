import { SET_CACHE_IDS } from "../actions/cacheIdAction";

const initState = {
  md: "",
  hot: "",
};

const cacheIdReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_CACHE_IDS:
      return {
        ...state,
        md: action.payload.md,
        hot: action.payload.hot,
      };
    default:
      return state;
  }
};

export default cacheIdReducer;
