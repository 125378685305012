import PropTypes, { number } from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, Link } from "react-router-dom";
// ** import { changeLanguage } from "redux-multilanguage";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
// import Logo from "../../components/header/Logo";
import NavMenu from "../../components/header/NavMenu";
import NavMenuAll from "../../components/header/NavMenuAll";
import IconGroup from "../../components/header/IconGroup";
// import MobileMenu from "../../components/header/MobileMenu";
import HeaderTop from "../../components/header/HeaderTop";
import WebService from '../../util/webService';
import constant from '../../util/constant';
// import { setLocalData } from '../../util/helper';
import { setUser } from "../../redux/actions/userAction";
import { setLocalData, getLocalData } from '../../util/helper';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { setCompany, setExchange } from "../../redux/actions/storeAction";
import { getInstance } from "../../lib/globalTracker";
import { fetchMultilanguageData } from "../../redux/actions/multilanguageAction";

// test commit
// ** import { getCurrentLocation } from "../../redux/actions/userAction";
// ** import LanguageCurrencyChanger from "../../components/header/sub-components/LanguageCurrencyChanger";
const Header = ({
	merchant, layout, top, borderStyle, headerPaddingClass, headerPositionClass, headerBgClass, defaultStore, currentLanguageCode, strings, setUser, setCompany, setExchange, userData, dispatch /* getCurrentLocation, */
}) => {
	const history = useHistory();
	const location = useLocation();
	const path = location.pathname;
	const globalTracker = getInstance(currentLanguageCode);
	//const [scroll, setScroll] = useState(0);
	//const [headerTop, setHeaderTop] = useState(0);
	const [message, setMessage] = useState('')
	const [categoryData, setCategoryData] = useState([]);
	//const [categoryData2, setCategoryData2] = useState([]);
	const [contentData, setContentData] = useState([]);
	const [searchData, setSearchData] = useState([]);
	const [searchText, setSearchText] = useState('');
	const [isMenuOpen, setIsMenuOpen] = useState(false); 
	const [isLoading, setIsLoading] = useState(true);
	const searchInputRef = useRef(); // 드롭다운 메뉴에 대한 참조
	// const multilanguageData = JSON.parse(localStorage.getItem("redux_localstorage_simple")).multilanguage.languages;
	// console.log('multilanguageData', multilanguageData)

	
	useEffect(() => {
    if (path.startsWith('/search/')) {
      const savedSearchText = localStorage.getItem('searchText');
      if (savedSearchText) {
        setSearchText(savedSearchText);
      } else {
        setSearchText(''); // 저장된 검색어가 없을 때 빈 값 설정
      }
    } else {
      setSearchText(''); // 현재 경로가 /search/로 시작하지 않을 때 빈 값 설정
    }
  }, [path]);

  useEffect(() => {
    const handleClickOutside =(e)=> {
      if (
        searchInputRef.current &&
        !searchInputRef.current.contains(e.target)
      ) {
        setIsMenuOpen(false); 
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchInputRef]);

//   useEffect(() => {

// 	 dispatch(fetchMultilanguageData(currentLanguageCode))
// 	 .then((data) => {
// 	   localStorage.setItem('multilanguage', JSON.stringify(data));
// 	   setIsLoading(false); // 데이터 로드 완료
// 	 })
// 	 .catch((error) => {
// 	   console.error('Failed to fetch multilanguage data:', error);
// 	   setIsLoading(false); // 데이터 로드 실패
// 	 });
//   }, [dispatch, currentLanguageCode]);

	useEffect(() => {
		const getCategoryHierarchy = async () => {
			const cachedData = sessionStorage.getItem('category');
			const langData = sessionStorage.getItem('lang');
			if (cachedData && langData === currentLanguageCode) {
				setCategoryData(JSON.parse(cachedData));
				return JSON.parse(cachedData);
			}
			let action = constant.ACTION.CATEGORY + `?count=1600&page=0&store=DEFAULT&lang=${currentLanguageCode}` 
			// +"&lang=" + currentLanguageCode;
			try {
				let response = await WebService.get(action);
				if (response && response.categories) {
					setCategoryData( response.categories );
					sessionStorage.setItem('category', JSON.stringify(response.categories));
					sessionStorage.setItem('lang', JSON.stringify(currentLanguageCode));
					
				}
			} catch (error) {
				console.log(error.messages)
				// console.log(error)
				// history.push('/not-found')
			}
		}

	
		/*
		const getContentMessage = async () => {
			let action = constant.ACTION.CONTENT + constant.ACTION.BOXES + constant.ACTION.HEADER_MESSAGE + '?lang=' + currentLanguageCode;
			try {
				let response = await WebService.get(action);
				if (response && response.description) {
					setMessage(response.description.description);
				}
			} catch (error) {
			}
		}
		const getContent = async () => {
			// TODO PAGE + COUNT
			let action = constant.ACTION.CONTENT + constant.ACTION.PAGES + '?page=0&count=20&store=' + defaultStore + '&lang=' + currentLanguageCode;
			try {
				let response = await WebService.get(action);
				if (response && response.items) {
					setContentData(response.items)
				}
			} catch (error) {
			}
		}
		// */
		const fetchData = async () => {
			try {
				if (getLocalData('thekey') === process.env.REACT_APP_BACKEND) {
					setLocalData('thekey', process.env.REACT_APP_BACKEND);
				} else {
					setLocalData('thekey', process.env.REACT_APP_BACKEND);
				}
				const response = await WebService.get(process.env.REACT_APP_BACKEND + '/actuator/health/ping');
				if (response && response.status === 'UP') {
					getCategoryHierarchy();
					// ** getContent();
					// ** getContentMessage();
				} else {
					history.push('/not-found');
				}
			} catch (error) {
				history.push('/not-found');
			}
		};
		fetchData();
		setCompany()
		setExchange()

		return () => {}; // Cleanup 함수
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [history,defaultStore, currentLanguageCode, setCategoryData, setContentData, setMessage]);

	/*
	const checkServerHealth = async () => {
		// let action = 'actuator/health/ping';
		try {
			let response = await WebService.get(process.env.REACT_APP_BACKEND + '/actuator/health/ping');
			if (response) {
				// console.log(response)
				if (response.status === 'UP') {
					setMerchant()
					// ** getCurrentLocation();
					getCategoryHierarchy();
					//getCategoryHierarchyAll();
					getContent();
				} else {
					history.push('/not-found')
				}
			}
		} catch (error) {
			history.push('/not-found')
		}
	}
	const handleScroll = () => {
		setScroll(window.scrollY);
	};
	// */
	const onSearch = async (e) => {
		const query = e.target.value;
		setSearchText(e.target.value)
		if (e.target.value.length >= 2) {
			let action = constant.ACTION.SEARCH + constant.ACTION.AUTOCOMPLETE
			// +"?lang=" + currentLanguageCode;;
			let param = {
				"deviceId": userData.userData?.id || userData.deviceID,
				"lang":"ko",
				"q": query,
				"size":5,
				"number":0
			}
			try {
				let response = await WebService.post(action, param);
				if (response && response.values) {

					setSearchData(response.values)
					console.log("searchData", response.values);
				}

			} catch (error) {
				console.log(error, '------------')
			}
		}else {
		setSearchData([]);
		}
	}
	const onSelectedSearch = (data) => {
		setSearchText(data)
		setIsMenuOpen(false)

		globalTracker.onEvent({
			event_id: 'search_autocomplete_clk', // Event ID
			event_type: 'click', // Event type
			event_name: 'button_click', // Event name
			params: {
				keywords_clk: data,
				keywords_exp: searchData.join(','),
			}
		});

		
		// searchText && history.push('/search/' + searchText )
	}
	const keyDownFunction = (e) => {
		if (e.keyCode === 13) {
			onSearchClick()		
		}
	}
	const handleSubmit = (event) => {
		event.preventDefault(); 
		localStorage.setItem('searchReset', true);
		onSearchClick(); 
	};
	const onSearchClick = () => {
		localStorage.setItem('searchText', searchText);
		globalTracker.onEvent({
			event_id: 'search_query_clk', // Event ID
			event_type: 'click', // Event type
			event_name: 'button_click', // Event name
			params: {
				query: searchText,
				filter_options: false,
			}
		});	
		history.push(`/search/${searchText}?query=${searchText}&offset=1&options=false`)
	}

	/* ==============================
	* 240508 : Ready Msg.
	================================= */
	const commingSoon = () => {
		alert( strings["Coming soon."] );
	}

	return (
		<header id="header-wrap">
			<div className="pkgs">
				<div id="gnavi">
					<div className="container">
						<HeaderTop borderStyle={borderStyle} />
					</div>
				</div>
				<div id="head">
					<div className="container">
						<div className="row1">
							<div className="boxLogo">
								{/* {merchant.logo != null && <Logo imageUrl={merchant.logo.path} logoclassName="logo" />} */}
								<Link to={process.env.PUBLIC_URL + "/"}>
									<img alt="" src="/assets/img/logo/logo.svg" />
								</Link>
							</div>
							<div className="boxSearch" >
								{/* <button className="search-active" onClick={e => handleClick(e)}>
									<i className="pe-7s-search" />
								</button> */}
								<form onSubmit={handleSubmit} >
									<div className="inner">
										<input type="text" className="searchKeywd" placeholder={strings["Search"]} value={searchText} onFocus={()=> setIsMenuOpen(true)} onKeyDown={(e) => keyDownFunction(e)} onChange={e => onSearch(e)} />
										<button type="button" className="schVoice d-none" onClick={commingSoon}>
											<i className="ri-mic-line ico"></i>
											<span className="txt">{strings["Voice search"]}</span>
										</button>
										<button type="button" className="schImg d-none" onClick={commingSoon}>
											<i className="ri-camera-2-line ico"></i>
											<span className="txt">{strings["Image search"]}</span>
										</button>
										<button type="submit" className="schTxt button-search">
											<i className="ri-search-line ico"></i>
											<span className="txt">{strings["Text search"]}</span>
										</button>
									</div>
								</form>
								{
									isMenuOpen && searchData.length > 0 &&
									<div className="autoComplete" ref={searchInputRef} >
										<div className="shopping-cart-content">
											<ul>
												{
													searchData.map((value, index) => {
														return (
															<li className="single-shopping-cart" key={index} >
																<p onClick={() => onSelectedSearch(value)}>{value}</p>
															</li>
														)
													})
												}
											</ul>
										</div>
									</div>
								}
							</div>
							<div className="boxIcon">
								<div className="headerMsg" dangerouslySetInnerHTML={{ __html:message.replace("<![CDATA[", "").replace("]]>", "") }}></div>
								<IconGroup />
							</div>
						</div>
						<div className="row2">
							<div className="boxNav">
								<NavMenu categories={categoryData} contents={contentData} />
							</div>
						</div>
					</div>
					{/* <MobileMenu categories={categoryData} contents={contentData} /> */}
					<NavMenuAll categories={categoryData} />
				</div>
			</div>
		</header>
	);
};
Header.propTypes = {
	// merchant: PropTypes.string,
	borderStyle: PropTypes.string,
	headerPaddingClass: PropTypes.string,
	headerPositionClass: PropTypes.string,
	layout: PropTypes.string,
	top: PropTypes.string,
	setMerchant: PropTypes.func,
	dispatch: PropTypes.func,
	defaultStore: PropTypes.string,
	currentLanguageCode: PropTypes.string,
	setUser: PropTypes.func,
};
const mapStateToProps = state => {
	return {
		userData:state.userData,
		merchant: state.merchantData.merchant,
		currentLanguageCode: state.currentLanguageCode,
		defaultStore: state.merchantData.defaultStore
	};
};
const mapDispatchToProps = dispatch => {
	return {
		setUser: (data) => {
			dispatch(setUser(data));
		},
		setCompany: (data) => {
			dispatch(setCompany(data));
		},
		setExchange: () => {
			dispatch(setExchange());
		},


		/*
		getCurrentLocation: () => {
			dispatch(getCurrentLocation())
		}
		// */
	};
};
export default connect( mapStateToProps, mapDispatchToProps )(multilanguage(Header));