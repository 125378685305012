import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy, useState } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Switch, Route,Redirect } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { multilanguage, loadLanguages } from "redux-multilanguage";
import { connect } from "react-redux";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import Loader from "./components/loader/loader";
import Cookie from "./components/consent/Cookie";
import Cookies from "universal-cookie";
import { setShopizerCartID } from "./redux/actions/cartActions";
import "./assets/scss/layout.scss";
import ButtonLoggerWrapper from "./util/ButtonLoggerWrapper";
import Layout from "./layouts/Layout";
// import Header from "./wrappers/header/Header";
// import WebService from "./util/webService";
import MyPageLayout from "./layouts/MyPageLayout";

// FireBase
// import "./firebase-messaging-sw.js";
// 누적 브런치

// var sha512 = require('js-sha512').sha512;
// home pages
const Home = lazy(() => import("./pages/home/Home"));

// shop pages
const Category = lazy(() => import("./pages/category/Category"));

// product pages
const ProductDetail = lazy(() => import("./pages/product-details/ProductDetail"));

// oem (Gunu)
const OemList = lazy(() => import("./pages/oem/OemList"));
const OemDetail = lazy(() => import("./pages/oem-details/OemDetail"));

// other pages
// const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));

const LoadModule = lazy(() => import("./components/omniOne/LoadModule.jsx"));
const Register = lazy(() => import("./pages/other/Register"));
const Register1 = lazy(() => import("./pages/other/Register1"));
const Register2 = lazy(() => import("./pages/other/Register2"));
const Register3_individual = lazy(() => import("./pages/other/Register3_individual"));
const Register3_enterprise = lazy(() => import("./pages/other/Register3_enterprise"));
const Register3_cn = lazy(() => import("./pages/other/Register3_cn"));
const Register4_success = lazy(() => import("./pages/other/Register4_success"));
const Login = lazy(() => import("./pages/other/Login"));
const ForgotPassword = lazy(() => import("./pages/other/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/other/ResetPassword"));
const RecentOrder = lazy(() => import("./pages/other/Orders/RecentOrder"));
const OrderDetails = lazy(() => import("./pages/other/OrderDetails"));
const NotFound = lazy(() => import("./pages/other/NotFound"));
const OrderConfirm = lazy(() => import("./pages/other/OrderConfirm"));
const Content = lazy(() => import("./pages/content/Content"));
const SearchProduct = lazy(() => import("./pages/search-product/SearchProduct"));

// ** 240329 : Customize Styleguide page.
const StyleGuide = lazy(() => import("./pages/styleguide/StyleGuide"));
const AppGuide = lazy(() => import("./pages/styleguide/AppGuide"));

// ** 240329 : Customize Main menu.
const SelectionProducts = lazy(() => import("./pages/products/SelectionProducts"));
const Products = lazy(() => import("./pages/products/Products"));
const GqMarket = lazy(() => import("./pages/gqmarket/gqMarket"));
const SourcingCrowd = lazy(() => import("./pages/sourcing-crowd/Sourcing-crowd"));

// ** 240329 : Customize Footer menu.
const AboutUs = lazy(() => import("./pages/aboutUs/AboutUs"));
const UseTerms = lazy(() => import("./pages/useTerms/UseTerms"));
const privacyPolicy = lazy(() => import("./pages/privacyPolicy/PrivacyPolicy"));
const TermsUse = lazy(() => import("./pages/termsUse/TermsUse"));
const EmailPolicy = lazy(() => import("./pages/emailPolicy/EmailPolicy"));
const IpCenter = lazy(() => import("./pages/ipCenter/IpCenter"));
const About1688 = lazy(() => import("./pages/about1688/About1688"));

// ** 240429 : Customize Main page link.
const HomeMdReccommend = lazy(() => import("./pages/home/HomeMdReccommend"));
const HomePopularItem = lazy(() => import("./pages/home/HomePopularItem"));
const HomeHotDeal = lazy(() => import("./pages/home/HomeHotDeal"));
const HomeStoreList = lazy(() => import("./pages/home/HomeStoreList"));
const HomeStoreView = lazy(() => import("./pages/home/HomeStoreView"));

// ** 240504 : Customize My page link.
const Favorite = lazy(() => import("./pages/other/MyPage/Favorite"));
const MyReview = lazy(() => import("./pages/other/MyPage/MyReview"));
const Cart = lazy(() => import("./pages/other/MyPage/CartTest"));
const Checkout = lazy(() => import("./pages/other/MyPage/Checkout"));
const OemCheckout = lazy(() => import("./pages/other/MyPage/OemCheckout"));
const FinishCheckout = lazy(() => import("./pages/other/Checkouts/FinishCheckout"));

const Checkin = lazy(() => import("./pages/other/MyPage/Checkin"));
const CheckinError = lazy(() => import("./pages/other/MyPage/CheckinError"));

const MyMain = lazy(() => import("./pages/other/MyPage/MyMain"));
const MyAccount = lazy(() => import("./pages/other/MyPage/MyAccount"));
const MyAddress = lazy(() => import("./pages/other/MyPage/MyAddress"));
const MyAccountIndividual = lazy(() => import("./pages/other/MyPage/MyAccount_individual"));
const MyAccountEnterprise = lazy(() => import("./pages/other/MyPage/MyAccount_enterprise"));
const MyAccountAuth = lazy(() => import("./pages/other/MyPage/MyAccountAuth"));
const MyWithdraw = lazy(() => import("./pages/other/MyPage/MyWithdraw"));
const MyWithdrawSuccess = lazy(() => import("./pages/other/MyPage/MyWithdrawSuccess"));

/** -- 240509 : Enterprise Member > Seller section */
const SellerOrderList = lazy(() => import("./pages/other/MyPage/enterprise/Seller/sellerOrder/SellerOrderList"));
const SellerOrderDetail = lazy(() => import("./pages/other/MyPage/enterprise/Seller/sellerOrder/SellerOrderDetail"));
const MySellerMain = lazy(() => import("./pages/other/MyPage/enterprise/Seller/MySellerMain"));
const MySellerStore = lazy(() => import("./pages/other/MyPage/enterprise/Seller/MySellerStore"));
const MySellerDelivery = lazy(() => import("./pages/other/MyPage/enterprise/Seller/MySellerDelivery"));
const MySellerAuth = lazy(() => import("./pages/other/MyPage/enterprise/Seller/MySellerAuth"));
const MySellerAsset = lazy(() => import("./pages/other/MyPage/enterprise/Seller/MySellerAsset"));
const MySellerSettlement = lazy(() => import("./pages/other/MyPage/enterprise/Seller/sellerOrder/SellerSettlement"));
const MySellerSmartContents = lazy(() => import("./pages/other/MyPage/enterprise/Seller/MySellerSmartContents"));

// 수출 통관 주석
// const MySellerExport = lazy(() => import("./pages/other/MyPage/enterprise/Seller/MySellerExport"));
const MySellerProductList = lazy(() => import("./pages/other/MyPage/enterprise/Seller/MySellerProductList"));
const MySellerProductRegister = lazy(() => import("./pages/other/MyPage/enterprise/Seller/MySellerProductRegister"));
const MySellerLibrary = lazy(() => import("./pages/other/MyPage/enterprise/Seller/MySellerLibrary"));
const MySellerCsList = lazy(() => import("./pages/other/MyPage/enterprise/Seller/MySellerCsList"));
const MySellerChatList = lazy(() => import("./pages/other/MyPage/enterprise/Seller/MySellerCsChatList"));
const MySellerCsView = lazy(() => import("./pages/other/MyPage/enterprise/Seller/MySellerCsView"));
const MySellerCsWrite = lazy(() => import("./pages/other/MyPage/enterprise/Seller/MySellerCsWrite"));
const MyStore = lazy(() => import("./pages/other/MyPage/enterprise/Seller/MyStore"));

// Oem 임시 (Gunu)
const MySellerProductList1 = lazy(() => import("./pages/other/MyPage/enterprise/Seller/MySellerProductList1"));
const MySellerProductRegister1 = lazy(() => import("./pages/other/MyPage/enterprise/Seller/MySellerProductRegister1"));

/** -- 240604 : Cs center */
const CsCenterMain = lazy(() => import("./pages/CsCenter/CsCenterMain"));
const CsFaqList = lazy(() => import("./pages/CsCenter/CsFaqList"));
const CsFaqView = lazy(() => import("./pages/CsCenter/CsFaqView"));
const CsNoticeList = lazy(() => import("./pages/CsCenter/CsNoticeList"));
const CsNoticeView = lazy(() => import("./pages/CsCenter/CsNoticeView"));
const CsQnaList = lazy(() => import("./pages/CsCenter/CsQnaList"));
const CsQnaView = lazy(() => import("./pages/CsCenter/CsQnaView"));
const CsQnaWrite = lazy(() => import("./pages/CsCenter/CsQnaWrite"));
const CsChatList = lazy(() => import("./pages/CsCenter/CsChatList"));

// tmep component working page
const PartsOperation = lazy(() => import("./pages/partsOperation/PartsOperation"));

/** -- 240706 : GQ Market */
const GqRequest = lazy(() => import("./pages/gqmarket/gqRequest"));

/** -- 240809 : 바이어 가입안내 */
const JoinBuyer = lazy(() => import("./pages/etc/joinBuyer"));

/** -- 240827 : 주문안내 */
const OrderGuide = lazy(() => import("./pages/orderGuide/OrderGuide"));
const CustomProcess = lazy(() => import("./pages/orderGuide/CustomProcess"));
const CurrencyRatio = lazy(() => import("./pages/orderGuide/CurrencyRatio"));
const PriceGuide = lazy(() => import("./pages/orderGuide/PriceGuide"));
const AdditionalServices = lazy(() => import("./pages/orderGuide/AdditionalServices"));
const Faq = lazy(() => import("./pages/orderGuide/Faq"));
const UseGuide = lazy(() => import("./pages/useGuide/UseGuide"));

/** -- 240828 : 캐릭터 소개 */
const Character = lazy(() => import("./pages/character/Character"));

/** -- 240915 : OEM, 이용안내 */
const OemPromo = lazy(() => import("./pages/oem/OemPromo"));
const UserGuide = lazy(() => import("./pages/userGuide/UserGuide"));

/** -- 240920 : IP CENTER SAFETY */
const Ipcenter = lazy(() => import("./pages/ipCenter/IpCenter"));
const IpCenterRegister  = lazy(() => import("./pages/ipCenter/IpCenterRegister"));
const IpCenterView  = lazy(() => import("./pages/ipCenter/IpCenterView"));


//export default function App = (props) => {
const App = ({ dispatch }) => {
	const [isDeploying, setIsDeploying] = useState(false);
	useEffect(() => {
		var cart_cookie = window._env_.APP_MERCHANT + "_shopizer_cart";
		const cookies = new Cookies();
		let cookie = cookies.get(cart_cookie);
		if (cookie) {
			// ** console.log('cookie !!! ' + cookie);
			dispatch(setShopizerCartID(cookie));
		}
		// console.log(window._env_);
		document.documentElement.style.setProperty("--theme-color", window._env_.APP_THEME_COLOR);
		//if(cookies[cart_cookie]) {
		//  console.log('cookie !!! ' + cookies[cart_cookie]);
		//  props.dispatch(setShopizerCartID(cookies[cart_cookie]));
		//}
		dispatch(
			loadLanguages({
				languages: {
				//from merchant supported languages
				en: require("./translations/english.json"),
				// french language file deleted
				// fr: require("./translations/french.json"),
				ko: require("./translations/korean.json"),
				zh: require("./translations/chinese.json"),
				ja: require("./translations/japanese.json"),
				},
			})
		);
	});

	return (
		<ToastProvider placement="">
		<ButtonLoggerWrapper>
			<BreadcrumbsProvider>
			<Router>
				<Loader></Loader>
				<Cookie></Cookie>
				<ScrollToTop>
				<Suspense
					fallback={
						<div className="flone-preloader-wrapper">
							<div className="flone-preloader">
								<span></span>
								<span></span>
							</div>
						</div>
					}
				>
					<Switch>
					<Route path="/LoadModule" component={LoadModule}/>
					<Layout>
						<Route exact path="/" component={Home} />
						<Route exact path="/nonfont" component={NotFound} />
						<Route path="/parts/operation" component={PartsOperation} />

						{/* Shop pages */}
						<Route path="/category/:id" component={Category} />

						{/* Shop product pages */}
						<Route path="/product/:id" component={ProductDetail} />
						<Route path="/content/:id" component={Content} />
						<Route path="/search/:id" component={SearchProduct} />

						{/* Oem 임시(Gunu) */}
						<Route path="/oems" component={OemList} />
						<Route path="/oem/:id" component={OemDetail} />

						{/* Other pages */}
						<Route path="/contact" component={Contact} />
						<Route path="/register" component={Register} />
						<Route path="/register1" component={Register1} />
						<Route path="/register2" component={Register2} />
						<Route path="/register3_individual" component={Register3_individual} />
						<Route path="/register3_enterprise" component={Register3_enterprise} />
						<Route path="/register3_cn" component={Register3_cn} />
						<Route path="/register4_success" component={Register4_success} />
						<Route path="/login" component={Login} />
						<Route path="/forgot-password" component={ForgotPassword} />
						<Route path="/customer/:code/reset/:id" component={ResetPassword} />
						<Route path="/order-confirm" component={OrderConfirm} />
						<Route path={"/not-found"} component={NotFound} />

						{/* 240329 : Customize Styleguide page. */}
						<Route path="/styleguide" component={StyleGuide} />
						<Route path="/appguide" component={AppGuide} />

						{/* 240329 : Customize Main menu. */}
						<Route path="/selection" component={SelectionProducts} />
						{/* <Route path="/products" component={Products} /> */}
						<Route path="/gq-market" component={GqMarket} />
						<Route path="/sourcing-crowd" component={SourcingCrowd} />

						{/* 240429 : Customize Main page link. */}
						<Route path="/home-md-reccommend" component={HomeMdReccommend} />
						<Route path="/home-popular-item" component={HomePopularItem} />
						<Route path="/home-hot-deal" component={HomeHotDeal} />
						<Route path="/home-store-list" component={HomeStoreList} />
						<Route path="/home-store-view" component={HomeStoreView} />

						{/* 240406 : Customize Footer menu. */}
						<Route path="/aboutUs" component={AboutUs} />
						<Route path="/useGuide" component={UseGuide} />
						<Route path="/useTerms" component={UseTerms} />
						<Route path="/privacyPolicy" component={privacyPolicy} />
						<Route path="/termsUse" component={TermsUse} />
						<Route path="/emailPolicy" component={EmailPolicy} />
						<Route path="/ipCenter" component={IpCenter} />
						<Route path="/about1688" component={About1688} />

						{/* 240604 : Cs center */}
						<Route path="/cs-main" component={CsCenterMain} />
						<Route path="/cs-faq-list" component={CsFaqList} />
						<Route path="/cs-faq-view" component={CsFaqView} />
						<Route path="/cs-notice-list" component={CsNoticeList} />
						<Route path="/cs-notice-view" component={CsNoticeView} />

						{/* 240706 : GQ Market */}
						<Route path="/gq-request" component={GqRequest} />

						{/* 240504 : Customize My page link. */}

						<Route path="/checkinError" component={CheckinError} />
						<Route path="/my-withdraw-success" component={MyWithdrawSuccess} />
						<Route path="/my-store/:id" component={MyStore} />
						<Route path="/checkout" component={Checkout} />
						<Route path="/oemCheckout" component={OemCheckout} />
						<Route path="/finish-checkout" component={FinishCheckout} />
						<Route path="/join-buyer" component={JoinBuyer} />

						{/* 240827 : 주문 프로세서 */}
						<Route path="/order-guide" component={OrderGuide} />
						<Route path="/price-guide" component={PriceGuide} />
						<Route path="/custom-process" component={CustomProcess} />
						<Route path="/currency-ratio" component={CurrencyRatio} />
						<Route path="/additional-services" component={AdditionalServices} />
						<Route path="/faq" component={Faq} />

						{/* 240828 : 캐릭터 소개 */}
						<Route path="/character" component={Character} />

						{/* 240915 : OEM, 이용안내 */}
						<Route path="/oem-promo" component={OemPromo} />
						<Route path="/user-guide" component={UserGuide} />

						{/*  체크인 test*/}
						<Route path="/checkin" component={Checkin} />

						{/* My page */}
						<Route exact
							path={[
								"/my/main",
								"/my/recent-order",
								"/my/order-details/:id",
								"/my/account",
								"/my/address",
								"/my/account-individual",
								"/my/account-enterprise",
								"/my/account-auth",
								"/my/withdraw",
								"/my-seller/orderList",
								"/my-seller/orderList1",
								"/my-seller/orderdetail/:id",
								"/my-seller/settlement",
								"/my-seller/smartContents",
								"/my-seller/main",
								"/my-seller/store",
								"/my-seller/delivery",
								"/my-seller/auth",
								"/my-seller/asset",
								"/my-seller/export",
								"/my-seller/productList",
								"/my-seller/ipCenter",
								"/my-seller/ipCenterRegister",
								"/my-seller/productRegister",
								"/my-seller/ipCenterView",
								"/my-seller/library",
								"/my-seller/csList",
								"/my-seller/chatList",
								"/my-seller/csView/:id/product/:productId/type/:type",
								"/my-seller/csWrite/:id/product/:productId/type/:type",
								"/my/qnaList",
								"/my/chatList",
								"/my/qnaWrite",
								"/my/qnaView",
								"/my/cart",
								"/my/favorite",

								// 정산 내역 페이지 (주문 배송 완료 )
								"/my/settlement-detail",

								// 리뷰 관리 페이지
								"/my/review",

								// -- Oem 임시 (Gunu)
								"/my-seller/productList1",
								"/my-seller/productRegister1",
							]}
						>
						<MyPageLayout>
							<Switch>
							<Route path="/my/favorite" component={Favorite} />
							<Route path="/my/cart" component={Cart} />
							<Route path="/my/withdraw" component={MyWithdraw} />
							<Route path="/my/main" component={MyMain} />
							<Route path="/my/recent-order" component={RecentOrder} />
							<Route path="/my/order-details/:id" component={OrderDetails} />
							<Route path="/my/account" component={MyAccount} />
							<Route path="/my/address" component={MyAddress} />
							<Route path="/my/account-individual" component={MyAccountIndividual} />
							<Route path="/my/account-enterprise" component={MyAccountEnterprise} />
							<Route path="/my/account-auth" component={MyAccountAuth} />
							<Route path="/my/qnaList" component={CsQnaList} />
							<Route path="/my/chatList" component={CsChatList} />
							<Route path="/my/qnaView" component={CsQnaView} />
							<Route path="/my/qnaWrite" component={CsQnaWrite} />
							<Route path="/my/review" component={MyReview} />

							{/* 240509 : Enterprise Member > Seller section */}
							<Route path="/my-seller/orderList" component={SellerOrderList} />
							<Route path="/my-seller/orderdetail/:id" component={SellerOrderDetail} />
							<Route path="/my-seller/settlement" component={MySellerSettlement} />
							<Route path="/my-seller/smartContents" component={MySellerSmartContents} />
							<Route path="/my-seller/main" component={MySellerMain} />
							<Route path="/my-seller/store" component={MySellerStore} />
							<Route path="/my-seller/delivery" component={MySellerDelivery} />
							<Route path="/my-seller/auth" component={MySellerAuth} />
							<Route path="/my-seller/asset" component={MySellerAsset} />

							{/* 수출 통관 페이지 주석 */}
							{/* <Route path="/my-seller/export" component={MySellerExport} /> */}
							<Route path="/my-seller/productList" component={MySellerProductList} />
							<Route path="/my-seller/productRegister" component={MySellerProductRegister} />
							<Route path="/my-seller/library" component={MySellerLibrary} />
							<Route path="/my-seller/csList" component={MySellerCsList} />
							<Route path="/my-seller/chatList" component={MySellerChatList} />
							<Route path="/my-seller/csView/:id/product/:productId/type/:type" component={MySellerCsView} />
							<Route path="/my-seller/csWrite/:id/product/:productId/type/:type" component={MySellerCsWrite} />
							<Route path="/my-seller/ipCenter" component={IpCenter} />
							<Route path="/my-seller/ipCenterRegister" component={IpCenterRegister} />
							<Route path="/my-seller/ipCenterView" component={IpCenterView} />

							{/* OEM 임시 (Gunu) */}
							<Route path="/my-seller/productList1" component={MySellerProductList1} />
							<Route path="/my-seller/productRegister1" component={MySellerProductRegister1} />

							{/*  주문 배송 완료 */}
							<Route path="/my/settlement-detail" component={MySellerCsWrite} />
							</Switch>
						</MyPageLayout>
						</Route>


					</Layout>
					<Redirect to="/nonfont" />
					</Switch>
				</Suspense>

				</ScrollToTop>
			</Router>
			</BreadcrumbsProvider>
		</ButtonLoggerWrapper>
		</ToastProvider>
	);
};
App.propTypes = {
	dispatch: PropTypes.func,
};
export default connect()(multilanguage(App));
