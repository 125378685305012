import PropTypes from "prop-types";
// import React ,{ useEffect, useState } from "react";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
// import WebService from '../../util/webService';
// import constant from '../../util/constant';
// import { setCurrency } from "../../redux/actions/currencyActions";
// import LanguageCurrencyChanger from "./sub-components/LanguageCurrencyChanger";
// import { GoogleTranslateEntire } from '../../util/utilFnc';

const HeaderTop = ({ strings, currentLanguageCode, dispatch, borderStyle }) => {
	/*
	const [message, setMessage] = useState('')
	useEffect(() => {
		getContentMessage();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const getContentMessage = async () => {
		let action = constant.ACTION.CONTENT + constant.ACTION.BOXES + constant.ACTION.HEADER_MESSAGE + '?lang=' + currentLanguageCode;
		try {
			let response = await WebService.get(action);
			if (response) {
				setMessage(response.description.description);
			}
		} catch (error) {
		}
	}
	// */
	/*
	return (
		<div className={`header-top-wap ${borderStyle === "fluid-border" ? "border-bottom" : ""}`}>
			<LanguageCurrencyChanger currentLanguageCode={currentLanguageCode} dispatch={dispatch} />
			<div className="header-offer">
				240327 : Admin > Content management > Content Boxed > 에서 CONTENT.CODE를 'headerMessage' 로 입력해야 출력됨.
				<div className="headerMsg" dangerouslySetInnerHTML={{ __html:message.replace("<![CDATA[", "").replace("]]>", "") }}></div>
				<div className="headerLang" id="headerLang">
					<button type="button" className="langToggle"><i className="ri-global-line"></i><span>LANGUAGE</span></button>
					<div className="langSet">
						<ul>
							<li><a href="#googtrans(ko|zh-CN)" data-hash="googtrans(ko|zh-CN)"><span>중문간체</span></a></li>
							<li><a href="#googtrans(ko|zh-TW)" data-hash="googtrans(ko|zh-TW)"><span>중문번체</span></a></li>
							<li><a href="#googtrans(ko|en)" data-hash="googtrans(ko|en)"><span>영문</span></a></li>
							<li><a href="#googtrans(ko|ja)" data-hash="googtrans(ko|ja)"><span>일본어</span></a></li>
						</ul>
					</div>
				</div>
				<GoogleTranslateEntire target="#headerLang" />
			</div>
		</div>
	);
	// */
	return null;
};
HeaderTop.propTypes = {
	borderStyle: PropTypes.string,
	currentLanguageCode: PropTypes.string,
	dispatch: PropTypes.func,
	strings: PropTypes.object
};

/*
const mapStateToProps = state => {
	return {
		// currency: state.currencyData
	};
};
const mapDispatchToProps = dispatch => {
	return {
		setCurrency: currencyName => {
			dispatch(setCurrency(currencyName));
		}
	};
};
// */
export default connect()(multilanguage(HeaderTop));
