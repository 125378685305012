// import uuid from "uuid/v4";
import Cookies from "universal-cookie";
import { removeLocalData } from "../../util/helper";

import {
  // ADD_TO_CART,
  DECREASE_QUANTITY,
  // INCREASE_QUANTITY,
  DELETE_FROM_CART,
  DELETE_ALL_FROM_CART,
  GET_CART,
  GET_SHOPIZER_CART_ID,
  UPDATE_CART_DATA,
} from "../actions/cartActions";

const initState = {
  cartItems: {},
  cartID: "",
  cartCount: 0,
  orderID: "",
};

const cartReducer = (state = initState, action) => {
  const cartItems = state.cartItems;
  const product = action.payload;

  switch (action.type) {
    case GET_SHOPIZER_CART_ID:
      return {
        ...state,
        cartID: action.payload,
      };

    case GET_CART:
      const products = action.payload?.products || [];
      return {
        ...state,
        cartItems: { ...action.payload, products },
        cartCount: action.payload.cartItems?.length,
      };

    case DECREASE_QUANTITY:
      // Handle decrease quantity logic here
      break;

    case DELETE_FROM_CART:
      const updatedCartItems = cartItems.filter(
        (item) => item.id !== product.id
      );
      if (updatedCartItems.length === 0) {
        var cart_cookie = window._env_.APP_MERCHANT + "_shopizer_cart";
        const cookies = new Cookies();
        cookies.remove(cart_cookie);
        removeLocalData(GET_SHOPIZER_CART_ID);
        return {
          ...state,
          cartItems: [],
          cartCount: 0,
          cartID: "",
        };
      } else {
        return {
          ...state,
          cartCount: updatedCartItems.length,
          cartItems: updatedCartItems,
        };
      }

    case DELETE_ALL_FROM_CART:
      var cart_cookie1 = window._env_.APP_MERCHANT + "_shopizer_cart";
      const cookies1 = new Cookies();
      cookies1.remove(cart_cookie1);
      removeLocalData(cart_cookie1);
      return {
        ...state,
        cartItems: [],
        cartCount: 0,
        cartID: "",
        orderID: action.payload,
      };

    case UPDATE_CART_DATA:
      return {
        ...state,
        cartID: action.payload.code,
        cartItems: action.payload.cartItems,
        cartCount: action.payload.cartCount,
      };
    default:
      return state;
  }
};

export default cartReducer;
