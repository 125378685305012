import React, { Fragment } from "react";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
const LayoutBanner1Config = ({ items = [] }) => {
  return (
    <div className="poss">
      {items.map((item, index) => (
        <Fragment key={index}>
          {item.link.startsWith("http") ? (
            <a
              href={item.link}
              target="_blank"
              title="새창열기"
              className="boxPkg"
              rel="noopener noreferrer"
            >
              <div className="boxImg">
                <img src={item.img} alt="" />
              </div>
            </a>
          ) : (
            <Link to={item.link} className="boxPkg">
              <div className="boxImg">
                <img src={item.img} alt="" />
              </div>
            </Link>
          )}
        </Fragment>
      ))}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentLanguageCode: state.multilanguage.currentLanguageCode,
    defaultStore: state.merchantData.defaultStore,
    isLoading: state.loading.isLoading,
  };
};
export default connect(mapStateToProps)(multilanguage(LayoutBanner1Config));
