import $ from 'jquery';
class LayerPopCore {
	constructor( opt ) {
		this.opt = opt;
		this.init();
	}
	init() {
		this.initCast();
	}
	initCast() {
		const layer = this;
		this.defaultOpt = {
			title  					: "Layer-Popup"
			, width					: "500"
			, height				: "300"
			, min_width				: "500"
			, min_height			: "300"
			, max_width				: "100%"
			, max_height			: "100%"
			, url					: ""
			, lineage				: ""
			, dataType				: "json"
			, div_target			: ""
			, parameter				: {}
			, top 					: "0"
			, left 					: "0"
			, returnObj				: null
			, UnderReset			: false
			, animate_open			: "0"
			, animate_close			: "0"
			, animate_reload		: "0"
			, animate_move			: "slow"
			, close_button			: "<button class='popBtnClose' type='button'><span class='blind txt'>현재창 닫기</span><i class='ri-close-line ico'></i></button>"
			, reload_button			: "<button class='popBtnReload' type='button'><span class='blind txt'>다시열기</span><i class='ri-loop-left-line ico'></i></button>"
			, minsize_button		: "<button class='popBtnMinsize' type='button'><span class='blind txt'>창 최소화 하기</span><i class='ri-zoom-out-line ico'></i></button>"
			, maxsize_button		: "<button class='popBtnMaxsize' type='button'><span class='blind txt'>창 최대화 하기</span><i class='ri-zoom-in-line ico'></i></button>"
			, close_button_yn		: true
			, reload_button_yn		: false
			, minmaxsize_button_yn	: false
			, btnNum				: 0
			, extraClass			: null
			, translation			: false
		}

		// ** Custom Opt, Default Opt Merge.
		this.options = $.extend( {}, this.defaultOpt, this.opt );
		this.originWidth = this.opt.width;
		this.originHeight = this.opt.height;

		if ( LayerPopCore.isNullOrBlank( this.options.url ) && LayerPopCore.isNullOrBlank( this.options.div_target ) ) {
			alert( "호출 경로 및 출력대상이 없습니다." );
			return false;
		} else {
			//console.log( layer.options );
			layer.drawWindow();
			layer.showLayerPopup();
			layer.setLayerSize();
			layer.pageLoad();
		}
	}
	drawWindow() {
		const layer = this;
		if ( layer.options.extraClass === undefined || layer.options.extraClass === null ) {
			layer.window = $( "<div id='popupLayer' style='display:none;'></div>" );
		} else {
			layer.window = $( "<div id='popupLayer' style='display:none;' class='" + layer.options.extraClass + "'></div>" );
		}
		layer.con_wrap = $("<div id='popupWrap'></div>");
		layer.con_wrapIn = $("<div class='popWrap' tabindex='0'></div>");
		layer.con_wrap.append( layer.con_wrapIn );
		layer.con_head = $("<div class='popHead'><h1 class='popHeadTit'>" + layer.options.title + "</h1></div>");
		layer.con_cont = $("<div class='popCont'></div>");
		layer.con_foot = $("<div class='popFoot'></div>");
		layer.con_overlay = $("<div id='popupOverlay'></div>");
		layer.con_wrapIn.append( layer.con_head ).append( layer.con_cont ).append( layer.con_foot ).append( layer.win_close_btn );
		layer.window.append( layer.con_wrap );
		layer.window.append( layer.con_overlay );
		var btn_cnt = 0;

		// ** Reload Button generate.
		if ( layer.options.reload_button_yn === "true" )		layer.options.reload_button_yn = true;
		else layer.options.reload_button_yn = false;
		if ( layer.options.reload_button_yn ) {
			layer.win_reload_btn = $(layer.options.reload_button);
			layer.con_foot.append(layer.win_reload_btn);
			layer.win_reload_btn.on("click", function(){
				// ** layer.reload();
			});
			layer.win_reload_btn.addClass("pop-btn-" + btn_cnt);
			btn_cnt++;
			layer.options.btnNum++;
		}

		// ** Resize Button gernerate.
		if ( layer.options.minmaxsize_button_yn === "true" )	layer.options.minmaxsize_button_yn = true;
		else layer.options.minmaxsize_button_yn = false;
		if ( layer.options.minmaxsize_button_yn ) {
			layer.win_minsize_btn = $(layer.options.minsize_button);
			layer.win_maxsize_btn = $(layer.options.maxsize_button);
			layer.con_foot.append(layer.win_minsize_btn);
			layer.con_foot.append(layer.win_maxsize_btn);
			layer.win_minsize_btn.on("click", function(){
				$(this).hide();
				layer.win_maxsize_btn.show();
				layer.options.width = layer.originWidth;
				layer.options.height = layer.originHeight;
				layer.setLayerSize();
			});
			layer.win_maxsize_btn.on("click", function(){
				$(this).hide();
				layer.win_minsize_btn.show();
				layer.options.width = layer.options.max_width;
				layer.options.height = layer.options.max_height;
				layer.setLayerSize();
			});
			layer.win_minsize_btn.addClass("pop-btn-" + btn_cnt).hide();
			layer.win_maxsize_btn.addClass("pop-btn-" + btn_cnt);
			btn_cnt++;
			layer.options.btnNum++;
		}

		// ** Close Button gernerate.
		if ( layer.options.close_button_yn === "true" )			layer.options.close_button_yn = true;
		else layer.options.close_button_yn = false;
		if ( layer.options.close_button_yn ) {
			layer.win_close_btn = $(layer.options.close_button);
			layer.con_foot.append(layer.win_close_btn);
			layer.win_close_btn.on("click", function(){
				layer.closeLayerPopup();
			});
			layer.con_overlay.on("click", function(){
				layer.closeLayerPopup();
			});
			layer.win_close_btn.addClass("pop-btn-" + btn_cnt);
			btn_cnt++;
			layer.options.btnNum++;
		}

		// ** Markup Placement
		$("body").append( layer.window );
	}
	showLayerPopup() {
		var layer = this;

		// ** Body태그 스크롤 유지. 스크롤 제거시 아래 코드 복원
		// ** $(document.body).css("overflow", "hidden");

		// ** SHOW. css 애니메이션으로 대체.
		// ** layer.window.show(layer.options.animate_open);
		layer.window.show( 100,
			function() {
				layer.window.addClass("show");
				setTimeout(
					function() {
						layer.con_wrap.addClass("show");
					}
				, 250 );
			}
		);

		// ** 접근성 이슈. 레이어팝업에 포커스
		layer.con_wrapIn.focus();
	}
	closeLayerPopup() {
		var layer = this;
		// ** Body태그 스크롤 유지하므로, 복원 불필요. 스크롤 제거/생성 필요시 아래 코드 복원.
		// ** $(document.body).css("overflow","visible");

		// ** HIDE. css 애니메이션으로 대체.
		layer.con_wrap.removeClass("show");
		setTimeout(
			function() {
				layer.window.addClass("hide");
				setTimeout(
					function() {
						layer.window.removeClass("show hide");
					}
				, 250 );
				setTimeout(
					function() {
						layer.window.hide();
						$(layer.options.returnObj).focus();
						layer.window.removeData("layerPopup");
						layer.window.remove();
						$(window).off('resizeEnd');
					}
				, 250 );
			}
		, 250 );
	}
	setLayerSize() {
		var layer = this;
		// ** 창 사이즈 선언. 화면 가운데 위치는 css에서 스타일링.
		// ** 모바일에서는 css에서 강제(!important) 스타일링.
		let reCalW = 0;
		let reCalH = 0;

		if ( layer.options.width.indexOf("%") > -1 ) {
			reCalW = layer.options.width;
		} else {
			if ( $(window).width() <= layer.options.width ) {
				reCalW = $(window).width();
			} else {
				reCalW = layer.options.width;
			}
		}

		if ( layer.options.height.indexOf("%") > -1 ) {
			reCalH = layer.options.height;
		} else {
			if ( $(window).height() <= layer.options.height ) {
				reCalH = $(window).height();
			} else {
				reCalH = layer.options.height;
			}
		}
		layer.con_wrap.css({ "width":reCalW, "height":reCalH }).addClass("has" + layer.options.btnNum + "Btn" );

		// ** Body태그 스크롤 유지. 스크롤 제거시 아래 코드 복원
		// ** $(document.body).css("overflow", "hidden");
	}
	pageLoad() {
		var layer = this;
		if ( !LayerPopCore.isNullOrBlank( layer.options.div_target ) ) {
			var data = $(layer.options.div_target).html();
			layer.setContent(data);

			// ** 파라미터가 있을경우 Key:Value 쌍으로 추출. 실제 개발시 불필요.
			/*
			var data = "";
			if ( Object.keys( layer.options.parameter ).length > 0 ) {	// ** Parameter 있음
				data += "<h3>ㆍ파라미터 Key / Value 쌍</h3>";
				Object.keys( layer.options.parameter ).forEach( function( key, index ) {
					data += "[" + index + "] key : " + key + " / value : " + layer.options.parameter[key] + "<br>";
				});
				data += "<div class='ssgap'></div>";
				data += "<h3>ㆍ본문</h3>";
			} else {
			}

			// ** 선택된 Div 타겟 html코드 추가.
			data += $(layer.options.div_target).html();
			layer.setContent( data );
			// */
		} else if ( !LayerPopCore.isNullOrBlank( layer.options.url ) ) {
			let newParam = null;
			if ( Object.keys( layer.options.parameter ).length === 0 ) {
				// ** console.log( "Empty" );
				newParam = {};
			} else {
				// ** console.log( "Not Empty" );
				newParam = JSON.parse( layer.options.parameter );
			}
			$.ajax({
				type : "get"
				, url : layer.options.url
				, data : newParam
				, dataType : layer.options.dataType
				, success : function( data ) {
					var content;
					if ( layer.options.lineage && layer.options.lineage.trim() !== "" ) {
						var path = layer.options.lineage.split('.');
						content = data;
						for (var i = 0; i < path.length; i++) {
							var propName = path[i];
							if (propName.includes('[')) {
								var index = parseInt(propName.match(/\d+/)[0]);
								propName = propName.split('[')[0];
								content = content[propName][index];
							} else {
								content = content[propName];
							}
						}
					} else {
						content = data;
					}
					layer.setContent( content );
				}
				, error : function( xhr, status, error ) {
					alert("오류 또는 네트워크 시간지연이 발생했습니다.\n다시 시도해 주세요.");
				}
			});
		}
	}
	setContent( content ) {
		var layer = this;
		var transFnc = "";
		var inner_content = content || "No data." ;
		if ( layer.options.translation ) {
			transFnc	+=	"<div class='c-trans2' id='cTrans2'>";
			transFnc	+=		"<ul>";
			transFnc	+=			"<li><button type='button' class='lang cw-nBtn2' data-lang='ko'><span>Korean</span></button></li>";
			transFnc	+=			"<li><button type='button' class='lang cw-nBtn2' data-lang='zh-CN'><span>Chinese (Simplified)</span></button></li>";
			transFnc	+=			"<li><button type='button' class='lang cw-nBtn2' data-lang='en'><span>English</span></button></li>";
			transFnc	+=			"<li><button type='button' class='lang cw-nBtn2' data-lang='ja'><span>Japanese</span></button></li>";
			transFnc	+=			"<li><button type='button' class='lang cw-nBtn2' data-lang='vi'><span>Vietnamese</span></button></li>";
			transFnc	+=		"</ul>";
			transFnc	+=	"</div>";
			layer.con_cont.html( "<div class='popContScrollBox'>" + transFnc + "<div id='transTarget'>" + inner_content + "</div></div>" );

			let targetNode = $("#transTarget")
			let targetHtml = targetNode.html();
			let transResult = null;
			let targetLang;
			$("#cTrans2 .lang").on("click", function() {
				targetLang = $(this).data("lang");
				$.ajax({
					url : "https://translation.googleapis.com/language/translate/v2"
					, type : "POST"
					, dataType : "json"
					, data : {
						q : targetHtml
						, target : targetLang
						, key: window._env_.APP_TRANS_API_KEY
					}
					, success: function ( response ) {
						transResult = response.data.translations[0].translatedText;
					}
					, complete : function() {
						targetNode.html( transResult );
					}
					, error: function (error) {
						console.error('Translation error:', error);
					}
				});
				// */
			});
		} else {
			transFnc = "";
			layer.con_cont.html( "<div class='popContScrollBox'>" + inner_content + "</div>" );
		}
	}
	static isNullOrBlank(val) {
		if ( val === null || val === "" ) return true;
		else return false;
	}
}
export default LayerPopCore;