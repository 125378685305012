import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { isValidObject } from "../../../util/helper";
import { connect, useSelector } from "react-redux";
import { multilanguage } from "redux-multilanguage";
import { CurrencyChangerEachNations } from "../../../util/utilFnc";
import { setChar } from "../../../util/util";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const MenuCart = ({ cartData, deleteFromCart, defaultStore, strings, userData, membType, }) => {
	// let cartTotalPrice = 0;
	const { addToast } = useToasts();
	const [uriMemTypeValue, setUriMemTypeValue] = useState("");
	const [cartItems, setCartItems] = useState([]);
	const history = useHistory();
	useEffect(() => {
		setUriMemTypeValue(membType || "CUSTOMER");
		if (cartData) {
			setCartItems(cartData);
		}
	}, [cartData]);
	const totalPrice = cartItems?.cartItems?.reduce(
		(acc, checkbox) => acc + checkbox.subTotal,
		0
	);
	const formattedTotalPrice = setChar(totalPrice);
	const navigateToCheckoutPage = () => {
		history.push(`/checkout?type=multiple`, {
			directOrderCartItem: [],
			sku: cartItems.sku,
			code: cartItems.code,
		});
	};
	return (
		<div className="shopping-cart-content hideChild">
			{isValidObject(cartData) && cartItems.cartItems?.length > 0 ? (
				<Fragment>
					<ul>
						{cartItems.cartItems?.map((single, key) => {
							return (
								<li className="single-shopping-cart" key={key}>
									<div className="shopping-cart-img">
										<Link to={"/product/" + single.productId}>
											<img src={defaultImage(single)} alt="" className="img-fluid" />
										</Link>
									</div>
									<div className="shopping-cart-title">
										<h4 className="name">
											<Link to={"/product/" + single.productId}>
												{single?.description?.name}
											</Link>
										</h4>
										<div className="info">
											<span className="qty">
												{strings["Qty"]} : {single.quantity}
											</span>
											<span className="price" data-price={single.subTotal}>
												{setChar(single.subTotal)}
												{/* <CurrencyChangerEachNations
												targetChar={single.subTotal}
												nation="kr"
												/> */}
											</span>
										</div>
									</div>
									<div className="shopping-cart-delete">
										<button onClick={() => deleteFromCart( cartData.code, single, defaultStore, addToast ) } >
											<i className="fa fa-times-circle" />
										</button>
									</div>
									<div className="shopping-cart-options">
										{single?.variants?.map((variant) => (
											<dl className="" key={variant.id}>
												<dt className="text-start">{variant.option.name || variant.option.code}</dt>
												<dd className="text-start">{variant.optionValue.name || variant.optionValue.code}</dd>
											</dl>
										))}
									</div>
								</li>
							);
						})}
					</ul>
					<div className="shopping-cart-total">
						<dl>
							<dt>{strings["Total"]}</dt>
							<dd className="shop-total" data-price={formattedTotalPrice}>
								{setChar(formattedTotalPrice)}
								{/* <CurrencyChangerEachNations targetChar={totalPrice} nation="kr" /> */}
							</dd>
						</dl>
					</div>
					<div className="shopping-cart-btn">
						{!userData && (
							<Link className="cw-sBtn w100pF wMax100pF" to="/login">
								<span>{strings["Login"]}</span>
							</Link>
						)}
						{userData && (
							<Fragment>
								<Link className="cw-sBtn w-100-max-100" to={{ pathname: "/my/cart", state: { membType: membType }, }} data-memb-type={uriMemTypeValue} >
									<span>{strings["View Cart"]}</span>
								</Link>
								{/* {cartItems.cartItems?.length > 0 && (
								<a
									className="cg-sBtn"
									onClick={() => navigateToCheckoutPage()}
									data-memb-type={uriMemTypeValue}
								>
									<span>{strings["Checkout"]}</span>
								</a>
								)} */}
							</Fragment>
						)}
					</div>
				</Fragment>
			) : (
				<div className="d-flex flex-column items-center">
					<p className="noData">
						<i className="ri-shopping-cart-2-line" />
						<span>{strings["No items added to cart"]}</span>
					</p>
					{userData && (
						<Link className="cw-sBtn mw-100 " to={{ pathname: "/my/cart", state: { membType: uriMemTypeValue }, }} data-memb-type={uriMemTypeValue} >
							<span>{strings["View Cart"]}</span>
						</Link>
					)}
				</div>
			)}
		</div>
	);
};

MenuCart.propTypes = {
	cartData: PropTypes.object,
	//currency: PropTypes.object,
	deleteFromCart: PropTypes.func,
	strings: PropTypes.object,
};
function defaultImage(product) {
	if (product.images && product.images.length > 0) {
		return product.images[0].externalUrl;
	} else if (product.image.externalUrl != null) {
		return product.image.externalUrl;
	} else {
		return null;
	}
}
const mapStateToProps = (state) => {
	return {
		defaultStore: state.merchantData.defaultStore,
	};
};
export default connect(mapStateToProps, null)(multilanguage(MenuCart));