import React from 'react';
import {api_log} from "./apilogaxios";

class ButtonLogger extends React.Component {

  // 버튼 클릭 핸들러
  handleButtonClick = (event) => {
    api_log({url: window.location.href, action : event.target.innerText})
  }

  componentDidMount() {
    // 모든 버튼 클릭 이벤트를 부모 요소에서 캡처
    document.addEventListener('click', this.handleButtonClick);
  }

  componentWillUnmount() {
    // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
    document.removeEventListener('click', this.handleButtonClick);
  }

  render() {
    return this.props.children;
  }
}

// 모든 버튼을 감싸기 위한 컴포넌트
const ButtonLoggerWrapper = (props) => {
  return <ButtonLogger>{props.children}</ButtonLogger>;
};

export default ButtonLoggerWrapper;