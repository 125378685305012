export const SET_CACHE_IDS = "SET_CACHE_IDS";

export const setCacheId = (mdCacheId, hotCacheId) => {
  return (dispatch) => {
    dispatch({
      type: SET_CACHE_IDS,
      payload: { md: mdCacheId, hot: hotCacheId },
    });
  };
};
