import { getMessaging, getToken, deleteToken, onMessage } from "firebase/messaging";
import Constant from "./constant";
import WebService from "./webService";
import { isMobile } from "react-device-detect";
import { sendFCMUserData } from "./MobileBridge"; 
import { initializeApp } from "firebase/app";
import { setLocalData } from "./helper";

const logTAG = "FCMToken : "

const isLocalhost = window.location.protocol === "https:" || window.location.hostname === "localhost"

const vapidKey = process.env.REACT_APP_FB_VAPID_KEY
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FB_APIKEY,
    authDomain: process.env.REACT_APP_FB_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FB_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FB_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FB_APP_ID,
    measurementId: process.env.REACT_APP_FB_MEASUREMENT_ID
};

var app = null
var messaging = null

if (isLocalhost && !isMobile) {
    app = initializeApp(firebaseConfig)
    messaging = getMessaging(app)

    onMessage(messaging, (payload) => {
        // console.log("메시지가 도착했습니다.", payload);
        if (Notification.permission === 'granted') {
            new Notification(payload.notification.title, {
                body: payload.notification.body,
                // icon: "/favicon_sr.ico"
                icon: "push_ico.webp"
            })
        }
    });
}

export const getFCMToken = async () => {
    try {
        const currentToken = await getToken(messaging, { vapidKey: vapidKey }) 
        if (currentToken) {
            console.log(logTAG, currentToken)
            requestPermission()
            onMessage(messaging, (payload) => {
                if (Notification.permission === 'granted') {
                    new Notification(payload.notification.title, {
                        body: payload.notification.body
                    })
                }
            });
            return currentToken
        } else {
            console.warn(logTAG, "token is not available")
            return
        }
    } catch (err) {
        console.log(logTAG, err)
        return
    }
}

export const PostFCMToken = async (userEmail, userId) => {

    if (isMobile) {
        sendFCMUserData("LOGIN", userEmail, userId)
    } else {
        getFCMToken().then((token)=> {
            postFCMTokenInExternalAPI(userEmail, userId, token)
        }).catch((err)=> {
            console.error("error in PostFCMToken : ", err)
        })
    }
}

export const DeleteFCMToken = async () => {
    const userEmail = localStorage.getItem("chatId")
    if (isMobile) {
        sendFCMUserData("LOGOUT", userEmail, "")
    } else {
        try {
            const currentToken = await getToken(messaging, { vapidKey: vapidKey })
            if (currentToken) {
                DeleteFCMTokenInExtenalAPI(currentToken)
                await deleteToken(messaging)
                console.log(logTAG, "Token delete success")
            } else {
                console.warn(logTAG, "Delete fail. No match Token")
            }
        } catch (error) {
            console.error(logTAG, `Token delete fail! ERR : ${error}`)
        }
    }
    setLocalData("chatId", "")
}

const requestPermission = async () => {
    const permission = await Notification.requestPermission();
    const currentUrl = window.location.pathname;
    if (currentUrl === "/" && permission === "denied") {
        alert("알림 권한이 없으면 채팅, 상품의 안내를 받을 수 없습니다.")
        return;
    }
}

export const postFCMTokenInExternalAPI = async (userEmail, userId, FCMToken) => {
    let action = "firebase/token"

    const params = {
        id: null,
        token: FCMToken,
        customerId: userId,
        email: userEmail,
        osType: "WEB"
    }

    try {
        await WebService.EXT.post(action, params)
    } catch (error) {
        console.error("tokenWebService", error)
    }
}

const DeleteFCMTokenInExtenalAPI = async (FCMToken) => {
    let action = Constant.ACTION.FIREBASE + Constant.ACTION.TOKEN + "?token=" + FCMToken
    let response = await WebService.EXT.delete(action)
    if (response) {
        console.log(`deletetoken is work ${JSON.stringify(response)}`)
    } else {
        console.log(`deletetoken is no worked!`)
    }
}

