import { Fragment, useEffect, useRef } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";

const CategoryItemDepth3 = ({ item, submenu, onClickCategory, strings }) => {
  return (
    <div className="d3Unit" data-parent={submenu.code}>
      {submenu.children && submenu.children.length > 0 ? (
        <Fragment>
          <h5 className="lv3Tit">{submenu.description.name}</h5>
          <ul className="lv3" data-depth="3">
            {submenu.children.map(
              (submenu2, index3) =>
                submenu2.visible && (
                  <li key={index3}>
                    <Link
                      to={{
                        pathname: `/category/list`,
                        search: `?id=${submenu2.id}&name=${encodeURIComponent(
                          `${item.description.description}-${submenu.description.description}-${submenu2.description.description}`
                        )}`,
                      }}
                      onClick={() => onClickCategory(submenu2)}
                      data-id={submenu2.id}
                      data-idx={submenu2.code}
                    >
                      <div className="img">
                        <span className="imgContainer">
                          <img
                            loading="lazy"
                            src={`/images/category/${submenu2.code}.png`}
                            alt={submenu2.code}
                            onError={(e) => {
                              e.target.src = "/assets/img/_Category/cate_noImg.jpg";
                            }}
                          />
                        </span>
                      </div>
                      <span className="txt">{submenu2.description.name}</span>
                    </Link>
                  </li>
                )
            )}
          </ul>
          <p className="parentReqMsg">
            <span>{strings["Select Top Menu"]}</span>
          </p>
        </Fragment>
      ) : (
        <Fragment>
          <p className="parentReqMsg">
            <span>{strings["Select Top Menu"]}</span>
          </p>
        </Fragment>
      )}
    </div>
  ); 
};

export default CategoryItemDepth3;
