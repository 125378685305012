import React, { Component } from 'react';
import $ from 'jquery';
class TopMenu extends Component {
	componentDidMount() {
		this.init();
	}
	init() {
		this.bodyTag = $("body");
		this.root = $("#root", this.bodyTag );
		this.navTag = $("nav#mainNavi", this.root );
		this.targetWrap1 = $(".depth1-wrap", this.navTag );	this.targetWrap2 = $(".depth2-wrap", this.navTag );	this.targetWrap3 = $(".depth3-wrap", this.navTag );
		this.targetLi1 = $(".mn_li1", this.navTag );		this.targetLi2 = $(".mn_li2", this.navTag );		this.targetLi3 = $(".mn_li3", this.navTag );
		this.targetA1 = $(".mn_a1", this.navTag );			this.targetA2 = $(".mn_a2", this.navTag );			this.targetA3 = $(".mn_a3", this.navTag );
		this.targetTgl1 = $(".mn_tgl1", this.navTag );		this.targetTgl2 = $(".mn_tgl2", this.navTag );		this.targetTgl3 = $(".mn_tgl3", this.navTag );
		this.contW = $("body").width();
		this.contWLim = 1000;
		this.addNode1 = "<div class='depth2-tit'><span class='tit'>";
		this.addNode2 = "</span><span class='txt'>";
		this.addNode3 = "</span></div>";
		this.timerFncDelay = 1000;
		this.mNaviCtrl = $("#mNaviControllerBtn");
		this.mNaviOverlay = $("#mNaviOverlay");
		this.mNaviCloser = $("#mNaviCloser");
		this.mNaviPcOverlayFnc =
		this.timerFnc = null;
		this.menuTxt = [
			"메뉴에 대한 설명문구가 들어갑니다. #-1"
			, "메뉴에 대한 설명문구가 들어갑니다. #-2"
			, "메뉴에 대한 설명문구가 들어갑니다. #-3"
			, "메뉴에 대한 설명문구가 들어갑니다. #-4"
		];
		this.initCast();
	}
	initCast() {
		const nav = this;

		// ** Depth2 Menu Title Customize.
		nav.targetLi1.each( function( index, item ) {
			var thisBtnTit = $(".mn_a1 span", $(this) ).text();
			var thisAddNode	= nav.addNode1 + thisBtnTit + nav.addNode2 + nav.menuTxt[index] + nav.addNode3;
			$(".depth2-wrap", $(this) ).prepend( thisAddNode );
		});

		// ** Document Ready.
		$(function() {
			nav.mainNaviOpt();
		});

		// ** Browser Resize Event.
		$(window).resize(function() {
			if(this.resizeTO) clearTimeout(this.resizeTO);
			this.resizeTO = setTimeout(function() {
				$(this).trigger('resizeEnd');
			}, 200 );
		});
		var width = $(window).width();
		$(window).on('resizeEnd', function() {
			if ($(window).width() !== width) {
				nav.contW = $("body").width();
				nav.mainNaviOpt();
				width = $(window).width();
			}
		});
	}
	mainNaviOpt() {
		var nav = this;
		if ( nav.contW > nav.contWLim ) {
			nav.initV_Off();
			nav.initW_On();
		} else {
			nav.initW_Off();
			nav.initV_On();
		}
	}

	/* ==============================
	* Initialize Wide
	================================= */
	initW_On() {
		var nav = this;

		// ** Topmenu 모바일 스타일 제거.
		nav.bodyTag.addClass("tmP").removeClass("tmM");

		// ** 스타일링 초기화.
		nav.targetWrap2.css({ "display":"none" });
		//nav.targetWrap3.css({ "display":"none" });

		// ** 버튼 및 메뉴가 닫히지 않아야 하는 상황의 이벤트 추가.
		nav.navTag.on("mouseover focus", ".mn_a1", function() {
			nav.initW_OnOpen( $(this), $(".depth2-wrap", nav.targetWrap1 ) );
		})
		nav.navTag.on("mouseover focus", ".depth1-wrap", function() {
			nav.initW_OnClose();
		});

		// ** 전체 a태그 이벤트시 전체닫힘 타이머 구동
		nav.navTag.on("mouseout blur", "a", function() {
			nav.initW_Timer();
		});
		nav.navTag.on("mouseout blur", ".depth1-wrap", function() {
			nav.initW_Timer();
		});
	}
	initW_OnOpen( $obj, $actNot ) {
		var nav = this;
		clearTimeout( nav.timerFnc );
		clearTimeout( nav.mNaviPcOverlayFnc );

		var depthAct = $obj.parent().find(".depth2-wrap");
		var depthActNot = $actNot.not( depthAct );
		depthAct.slideDown( "fast" );
		depthActNot.slideUp( "fast" );

		// ** Topmenu Overlay CastOn
		nav.bodyTag.addClass("overlayShow").removeClass("overlayHide");
	}
	initW_OnClose() {
		var nav = this;
		clearTimeout( nav.timerFnc );
		clearTimeout( nav.mNaviPcOverlayFnc );
	}
	initW_Off() {
		var nav = this;
		nav.navTag.off("mouseover focus", ".mn_a1");
		nav.navTag.off("mouseover focus", ".depth1-wrap");
		nav.navTag.off("mouseout blur", "a");
		nav.navTag.off("mouseout blur", ".depth1-wrap");
	}
	initW_Timer() {
		var nav = this;
		clearTimeout( nav.timerFnc );
		nav.timerFnc = setTimeout(
			function() {
				$(".depth2-wrap", nav.target ).slideUp("fast");

				// ** Topmenu Overlay CastOff
				nav.bodyTag.addClass("overlayHide");
				clearTimeout( nav.mNaviPcOverlayFnc );
				nav.mNaviPcOverlayFnc = setTimeout(
					function() {
						nav.bodyTag.removeClass("overlayShow overlayHide");
					}
				, nav.timerFncDelay );
			}
		, nav.timerFncDelay );
	}

	/* ==============================
	* Initialize Vertical
	================================= */
	initV_On() {
		var nav = this;

		// ** Topmenu 모바일 스타일 삽입.
		nav.bodyTag.addClass("tmM").removeClass("tmP tmM_open");
		nav.mNaviOverlay.removeClass("show hide");

		// ** has_act 클래스 플래그 발견시 하위 카테고리 미리 열기
		/*
		$("li.has_act", nav.targetWrap1 ).each( function( index, item ) {
			if ( $(this).hasClass("has_sub") ) {
				$(this).addClass("open");
			}
			$("> div[class^='depth'][class$='-wrap']", $(this) ).show();
		});
		// */

		// ** Mobile 사이드메뉴 열기.
		nav.mNaviCtrl.on("click", function() {
			nav.bodyTag.addClass("tmM_open");
			nav.mNaviOverlay.addClass("show");
		});

		// ** Mobile 사이드메뉴 닫기. 닫기(×)버튼, 검정 반투명 오버레이.
		nav.mNaviOverlay.on("click", function() {
			nav.initV_OnClose();
		});
		nav.mNaviCloser.on("click", function() {
			nav.initV_OnClose();
		});
		nav.navTag.on("click", "button[class^=mn_tgl]", function() {
			nav.initV_OnOpen( $(this) );
		});

		// ** Mobile menu 클릭시 처리.
		nav.navTag.on("click", ".mn_a1", function() {
			nav.initV_OnClose();
		});
	}
	initV_OnOpen( btn ) {
		var depthAct = btn.parent();
		var depthActSib = depthAct.siblings();
		if ( depthAct.hasClass("on") ) {	// ** Close if open.
			depthAct.removeClass("on");
			$("> div[class^='depth']", depthAct ).slideUp();
		} else {							// ** Optional open and close.
			depthAct.addClass("on");
			$("> div[class^='depth']", depthAct ).slideDown();
			depthActSib.removeClass("on");
			$("> div[class^='depth']", depthActSib ).slideUp();
		}
	}
	initV_OnClose() {
		var nav = this;
		nav.bodyTag.removeClass("tmM_open");
		nav.mNaviOverlay.addClass("hide");
		setTimeout(
			function() {
				nav.mNaviOverlay.removeClass("show hide");
			}
		, 500 );
	}
	initV_Off = function() {
		var nav = this;
		nav.mNaviCtrl.off("click");
		nav.mNaviOverlay.off("click");
		nav.mNaviCloser.off("click");
		nav.navTag.off("click", "button[class^=mn_tgl]");
	}

	render() { return <></>; }
}
export default TopMenu;