// import PropTypes from "prop-types";
import React from "react";
import CookieConsent from "react-cookie-consent";
import { connect } from "react-redux";
// import { multilanguage, loadLanguages } from "redux-multilanguage";
import { multilanguage } from "redux-multilanguage";

const Cookie = ({ strings }) => {
	//* 임시로 매번 초기화 분석 필요
	strings = {
		"Accept": "확인"
		, "Cookie Consent": "본 웹사이트는 사용자경험 향상을 위해 쿠키를 수집하고 있습니다."
	};

	return (
		<CookieConsent location="bottom" buttonText={strings["Accept"]} >
			{strings["Cookie Consent"]}
		</CookieConsent>
	);
};
const mapStateToProps = state => {
	return {
		currentLanguageCode: state.multilanguage.currentLanguageCode,
	};
};
const mapDispatchToProps = dispatch => {
	return {
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(multilanguage(Cookie));