import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const CategoryItemDepth1 = ({ item, onClickCategory }) => {
  return (
    item.visible && (
      <li className={item.children && item.children.length > 0 ? "has_sub" : ""}>
        <Link
          to={{
            pathname: `/category/list`,
            search: `?id=${item.id}&name=${encodeURIComponent(`${item.description.description}`)}`,
          }}
          onClick={() => onClickCategory(item)}
          data-id={item.id}
          data-idx={item.code}
        >
          <span className="txt">{item.description.name}</span>
        </Link>
        {item.children && item.children.length > 0 && (
          <button type="button" className="toggle" data-id={item.id} data-idx={item.code}>
            <span className="blind">Open & Close</span>
          </button>
        )}
      </li>
    )
  );
};

export default CategoryItemDepth1;
