const initialState = {
  symbol: "₩",
  country: "KOR",
};

const currencyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CURRENCY_SYMBOL":
      return {
        ...state,
        symbol: action.payload.symbol,
        country: action.payload.country,
      };

    default:
      return state;
  }
};

export default currencyReducer;
