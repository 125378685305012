import PropTypes from "prop-types";
import React from "react";
import { changeLanguage } from "redux-multilanguage";
import { multilanguage } from "redux-multilanguage";
import { connect } from "react-redux";
const LanguageCurrencyChanger = ({
	// currency,
	// setCurrency,
	strings,
	merchant,
	currentLanguageCode,
	dispatch
}) => {
	let Languages = [{code: 'en', id: 1}, {code: 'ko', id: 2}, {code: 'cn', id: 3},]
	const changeLanguageTrigger = e => {
		
		const languageCode = e.target.value;
		dispatch(changeLanguage(languageCode));
		// window.location.reload();
	};

	// const setCurrencyTrigger = e => {
	//   const currencyName = e.target.value;
	//   setCurrency(currencyName);
	// };
	
	return (
		<div className="language-currency-wrap">
			<div className="header-lang">
				<span className="lLauncher">
					{/* { console.log( "merchant", merchant ) } */}
					{currentLanguageCode === "en" ? strings["en"] : currentLanguageCode === "fr" ? strings["fr"] : currentLanguageCode === "ko" ? strings["ko"] : currentLanguageCode === "zh" ? strings["zh"] : ""}
				</span>
				<div className="lList">
					{/* {merchant.supportedLanguages && ( */}
						<ul>
							{
								// 도커용 언어 하드 코딩
								// merchant &&  merchant.supportedLanguages.map((value, i) => {
								Languages.map((value, i) => {
									return (
										<li key={i}>
											<button value={value.code} onClick={e => changeLanguageTrigger(e)}>
												{strings[value.code]}
											</button>
										</li>
									)
								})
							}
						</ul>
					{/* )} */}
				</div>
			</div>
			{/* <div className="header-call">
				<p>{strings['Call Us']} : {merchant.phone}</p>
			</div> */}
		</div>
	);
};
LanguageCurrencyChanger.propTypes = {
	// setCurrency: PropTypes.func,
	// currency: PropTypes.object,
	currentLanguageCode: PropTypes.string,
	dispatch: PropTypes.func,
	strings: PropTypes.object
};
const mapStateToProps = state => {
	return {
		merchant: state.merchantData.merchant 
	};
};
// const mapDispatchToProps = dispatch => {
// 	return {
// 	};
// };
export default connect(
	mapStateToProps,
	null
)(multilanguage(LanguageCurrencyChanger));
// export default LanguageCurrencyChanger;
