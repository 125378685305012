import Tracker from "./track";

let _globalTracker;
// test
const getInstance = (lang) => {

  const deviceID = JSON.parse(localStorage.getItem("redux_localstorage_simple"))?.userData?.deviceID;
  const userId = JSON.parse(localStorage.getItem("redux_localstorage_simple"))?.userData?.userData?.id;
  if (!_globalTracker) {
    // Initialize Tracker instance
    _globalTracker = new Tracker({
      userId: userId, // current UserId
      deviceId: deviceID, // current deviceId
      lang: lang, // current language
      country: lang, // current country
      sessionTimeout: 360 // Session timeout, default is 360s
    });
  }

  return _globalTracker;
}

export { getInstance };