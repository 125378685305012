// import uuid from "uuid/v4";
import Cookies from "universal-cookie";
import { removeLocalData } from "../../util/helper";
import { DELETE_FROM_FAVORITE, GET_FAVORITE } from "../actions/favoriteActions";

const initState = {
  favoriteItems: {},
  // cartID: "",
  favoriCount: 0,
  // orderID: "",
};

const favoriteReducer = (state = initState, action) => {
  const favoriteItems = state,
    product = action.payload;

  if (action.type === GET_FAVORITE) {
    const products = action.payload?.items || [];

    return {
      ...state,
      favoriteItems: products,
      favoriCount: action.payload.recordsTotal,
    };
  }

  if (action.type === DELETE_FROM_FAVORITE) {
    const updatedCartItems = favoriteItems.favoriteItems.filter(
      (item) => item.id !== product.id
    );
    if (favoriteItems.favoriteItems.length === 0) {
      //remove from local storage
      //remove from cookie
      var cart_cookie = window._env_.APP_MERCHANT + "_shopizer_cart";
      const cookies = new Cookies();
      cookies.remove(cart_cookie);
      return {
        ...state,
        favoriteItems: {},
        favoriCount: action.payload.recordsTotal,
        cartID: "",
      };
    } else {
      return {
        ...state,
        favoriCount: updatedCartItems.length,
        favoriteItems: updatedCartItems,
      };
    }

    // return remainingItems(cartItems, product);
  }

  return state;
};

export default favoriteReducer;
